import React from "react"
import { Link } from "gatsby"

import HomeIcon from "../../assets/home.svg"
import BreadPathIcon from "../../assets/bread-path.svg"

const Bread = ({ breadPaths }) => {
  if (!breadPaths) {
    breadPaths = [
      {
        text: "breadPaths needs to be defined",
      },
    ]
  }

  const emptyBread = <div className="pt-1gap"></div>

  const standardBread = (
    <div className="max-w-12col11gap mx-auto py-1/2gap flex flex-wrap">
      <div className="-ml-1/2gap">
        <Link
          to="/"
          className="inline-flex no-underline pl-1/2gap mr-1 items-center"
        >
          <HomeIcon className="w-1gap h-1gap inline-block fill-current" />
          <span className="ml-2 underline">トップ</span>
        </Link>

        {breadPaths.map((path, i) => (
          <span className="inline-flex pl-1/2gap items-center" key={i}>
            <BreadPathIcon className="w-1gap inline-block fill-current -ml-1" />
            <span className="ml-1">
              {path.href ? (
                <Link to={path.href}>{path.text}</Link>
              ) : (
                <span>{path.text}</span>
              )}
            </span>
          </span>
        ))}
      </div>
    </div>
  )

  return (
    <div className="bg-black-thin text-white px-1gap shadow-2xl">
      {!breadPaths.length ? emptyBread : standardBread}
    </div>
  )
}

export default Bread
