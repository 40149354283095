import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import _ from "lodash"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismicGlobal {
        data {
          copyright {
            text
          }
          site_name_sub {
            text
          }
          site_name {
            text
          }
        }
      }
      allPrismicOnlineLesson {
        edges {
          node {
            data {
              order
              title {
                text
              }
            }
            uid
          }
        }
      }
      allPrismicOtherSupport {
        edges {
          node {
            uid
            data {
              order
              title {
                text
              }
            }
          }
        }
        nodes {
          id
        }
      }
    }

  `)

  const dataNode = data.prismicGlobal.data
  const copyright = dataNode.copyright.text

  let lessonEntries = data.allPrismicOnlineLesson.edges.map(entry => {
    return {
      uid: entry.node.uid,
      title: entry.node.data.title.text,
      order: entry.node.data.order,
    }
  })
  lessonEntries = _.sortBy(lessonEntries, entry => entry.order)

  let otherSupportEntries = data.allPrismicOtherSupport.edges.map(entry => {
    return {
      uid: entry.node.uid,
      title: entry.node.data.title.text,
      order: entry.node.data.order,
    }
  })
  otherSupportEntries = _.sortBy(otherSupportEntries, entry => entry.order)

  return (
    <footer className="pt-1gap bg-pattern1">
      <div className="bg-black text-white mb-2">
        <div className="w-full max-w-12col13gap p-1gap mx-auto">
          <ul className="md:flex flex-wrap leading-tight -ml-4 -mt-2">
            <li className="list-item-triangle-white whitespace-no-wrap ml-8 mt-2">
              <Link to="/">ホーム</Link>
            </li>
            {lessonEntries.map((entry, i) => {
              return (
                <li className="list-item-triangle-white whitespace-no-wrap ml-8 mt-2" key={i}>
                  <Link to={`/${entry.uid}/`}>
                    {entry.title}
                  </Link>
                </li>
              )
            })}
            {otherSupportEntries.map((entry, i) => {
              return (
                <li className="list-item-triangle-white whitespace-no-wrap ml-8 mt-2" key={i}>
                  <Link to={`/other-support/${entry.uid}/`}>
                    {entry.title}
                  </Link>
                </li>
              )
            })}
            <li className="list-item-triangle-white whitespace-no-wrap ml-8 mt-2">
              <Link to="/contact/">お問い合わせ・よくあるご質問</Link>
            </li>
            <li className="list-item-triangle-white whitespace-no-wrap ml-8 mt-2">
              <Link to="/order/">お申し込み</Link>
            </li>
          </ul>
        </div>
        <div className="w-full max-w-14col13gap border-white border-t text-left mx-auto">
          <small className="block w-full max-w-12col13gap p-1gap mx-auto text-base">
            {copyright}
          </small>
        </div>
      </div>
    </footer>
  )
}
export default Footer
