/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = () => {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      prismicGlobal {
        data {
          site_name {
            text
          }
          site_name_sub {
            text
          }
          author {
            text
          }
          description {
            text
          }
        }
      }
    }
  `)

  const metaDescription = data.prismicGlobal.data.description.text
  const title = data.prismicGlobal.data.site_name.text
  const author = data.prismicGlobal.data.author.text

  const meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={title}
      titleTemplate={`%s | ${title}`}
      meta={meta}
    />
  )
}

export default SEO
