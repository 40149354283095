import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import _ from "lodash"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicGlobal {
        data {
          copyright {
            text
          }
          site_name {
            text
          }
          site_name_sub {
            text
          }
        }
      }
      allPrismicOnlineLesson {
        edges {
          node {
            data {
              order
              title {
                text
              }
            }
            uid
          }
        }
      }
      allPrismicOtherSupport {
        edges {
          node {
            data {
              order
              title {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)

  const globalDataNode = data.prismicGlobal.data
  const siteName = globalDataNode.site_name.text
  const siteNameSub = globalDataNode.site_name_sub.text

  let lessonEntries = data.allPrismicOnlineLesson.edges.map(entry => {
    return {
      uid: entry.node.uid,
      title: entry.node.data.title.text,
      order: entry.node.data.order,
    }
  })
  lessonEntries = _.sortBy(lessonEntries, entry => entry.order)

  let otherSupportEntries = data.allPrismicOtherSupport.edges.map(entry => {
    return {
      uid: entry.node.uid,
      title: entry.node.data.title.text,
      order: entry.node.data.order,
    }
  })
  otherSupportEntries = _.sortBy(otherSupportEntries, entry => entry.order)

  const createNav1Data = () => {
    const result = []
    lessonEntries.forEach(entry => {
      result.push({
        path: `/${entry.uid}/`,
        title: entry.title,
      })
    })
    otherSupportEntries.forEach(entry => {
      if (entry.uid !== "art-academy-germany") {
        return
      }
      result.push({
        path: `/other-support/${entry.uid}/`,
        title: entry.title,
      })
    })
    return result
  }

  const createNav2Data = () => {
    const result = []
    otherSupportEntries.forEach(entry => {
      if (entry.uid === "art-academy-germany") {
        return
      }
      result.push({
        path: `/other-support/${entry.uid}/`,
        title: entry.title,
      })
    })
    return result
  }

  const nav1Data = createNav1Data()
  const nav2Data = createNav2Data()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const hamburgerClassName = isMenuOpen
    ? "hamburger hamburger--stand is-active"
    : "hamburger hamburger--stand"

  const menuStyle = {
    display: isMenuOpen ? "flex" : "none",
  }

  return (
    <div className="h-1col2gap">
      <header className="pb-1gap bg-pattern1 relative z-50 shadow-xl">
        <div className="bg-black text-white pt-1gap shadow-xl">
          <div className="w-full max-w-12col13gap mx-auto relative">
            <div className="flex items-center px-1gap py-1/2gap relative h-1col">
              <span className="whitespace-no-wrap">
                <Link
                  to="/"
                  className="text-4xl font-sans no-underline hover:underline"
                >
                  {siteName}
                </Link>
              </span>
              <span className="hidden md:block whitespace-no-wrap pl-2gap pt-1">
                {siteNameSub}
              </span>
              <span className="absolute right-0 top-0 bottom-0 flex items-center mr-1gap">
                <span className="border border-white rounded">
                  <button
                    className={hamburgerClassName}
                    type="button"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </span>
              </span>
            </div>

            <div
              className="border-white border-t p-1gap leading-tight flex -ml-1gap flex-col md:flex-row"
              style={menuStyle}
            >
              {/* 1st col */}
              <div className="pl-2gap pb-1gap md:w-1/3">
                <ul className="space-y-2">
                  {nav1Data.map((entry, i) => {
                    return (
                      <li className="list-item-triangle-white" key={i}>
                        <Link to={entry.path}>{entry.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>

              {/* 2nd col */}
              <div className="pl-2gap pb-1gap md:w-1/3">
                <ul className="space-y-2">
                  {nav2Data.map((entry, i) => {
                    return (
                      <li className="list-item-triangle-white" key={i}>
                        <Link to={entry.path}>{entry.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>

              {/* 3rd col */}
              <div className="pl-2gap pb-1gap md:w-1/3">
                <ul className="space-y-2">
                  <li className="list-item-triangle-white">
                    <Link to="/contact/">お問い合わせ・よくあるご質問</Link>
                  </li>
                  <li className="list-item-triangle-white">
                    <Link to="/order/">お申し込み</Link>
                  </li>
                </ul>
                {/*
                <ul className="space-y-2">
                  <li className="md:border-t md:border-l md:border-r md:border-b border-white md:p-1gap">
                    <span className="block ml-1gap list-item-triangle-white">
                      <Link to="/contact/">お問い合わせ</Link>
                    </span>
                  </li>
                  <li className="md:border-t md:border-l md:border-r md:border-b border-white md:p-1gap">
                    <span className="block ml-1gap list-item-triangle-white">
                      <Link to="/order/">お申し込み</Link>
                    </span>
                  </li>
                </ul>
                */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
